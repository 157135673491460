(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/hooks/assets/javascripts/use-get-matchstatus.js') >= 0) return;  svs.modules.push('/components/pool-betting/hooks/assets/javascripts/use-get-matchstatus.js');
"use strict";


const {
  useEffect,
  useState
} = React;
const {
  useSelector
} = ReactRedux;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_SM
} = svs.components.lbUtils.useMediaQuery;
const {
  selectSportEvent,
  selectLeagueFromSportEvent
} = svs.components.sportinfo.matchInfoRedux.selectors;
const {
  getIsOngoing,
  getSportInfoMatchState,
  getIsFinished
} = svs.components.sportinfo.common.matchState;
const {
  getStatusTextBySportEventAndLeague
} = svs.components.sportinfo.sportConfig.helpers;
const {
  statusTexts
} = svs.components.sportinfo.sportConfig.sports.Default;
const {
  SportEventStatus
} = svs.components.sport.sportEventCommon;
const {
  MatchResultTypes
} = svs.components.sport.tipsenShared.constants;
const getMatchStatusClass = sportEventStatus => {
  switch (sportEventStatus) {
    case SportEventStatus.Postponed:
    case SportEventStatus.Cancelled:
      return 'cancelled';
    default:
      return getSportInfoMatchState(sportEventStatus);
  }
};
const statusTextsShortToFull = (matchStatus, isSmall) => {
  switch (matchStatus) {
    case statusTexts.Cancelled.short:
      return statusTexts.Cancelled.full;
    case statusTexts.Postponed.short:
      return isSmall ? statusTexts.Postponed.short : statusTexts.Postponed.full;
    default:
      return matchStatus;
  }
};

const useGetMatchstatus = _ref => {
  var _getMatchStatusClass;
  let {
    matchId,
    matchResultState
  } = _ref;
  const sportEvent = useSelector(state => selectSportEvent(state, matchId));
  const league = useSelector(state => selectLeagueFromSportEvent(state, matchId));
  const isSmall = useMediaQuery(breakpoints.down(BREAKPOINT_SM));
  const [matchStatusText, setMatchStatusText] = useState(() => statusTextsShortToFull(getStatusTextBySportEventAndLeague(sportEvent, league), isSmall));
  const isFinished = matchResultState ? matchResultState >= MatchResultTypes.VERIFIED : false;
  const isOngoing = !isFinished && sportEvent ? getIsOngoing(sportEvent.sportEventStatus) || getIsFinished(sportEvent.sportEventStatus) : undefined;
  const [matchStatusClass, setMatchStatusClass] = useState("".concat(isFinished ? 'finished' : (_getMatchStatusClass = getMatchStatusClass(sportEvent.sportEventStatus)) === null || _getMatchStatusClass === void 0 ? void 0 : _getMatchStatusClass.toLowerCase()));
  useEffect(() => {
    var _getMatchStatusClass2;
    setMatchStatusClass("".concat(isFinished ? 'finished' : (_getMatchStatusClass2 = getMatchStatusClass(sportEvent.sportEventStatus)) === null || _getMatchStatusClass2 === void 0 ? void 0 : _getMatchStatusClass2.toLowerCase()));
    if (isFinished) {
      setMatchStatusText('FT');
      return;
    }
    if (!isOngoing) {
      setMatchStatusText(statusTextsShortToFull(getStatusTextBySportEventAndLeague(sportEvent, league)));
      return;
    }
    const matchClock = setInterval(() => {
      setMatchStatusText(statusTextsShortToFull(getStatusTextBySportEventAndLeague(sportEvent, league)));
    }, 1000);
    return () => {
      if (matchClock) {
        clearInterval(matchClock);
      }
    };
  }, [isOngoing, isFinished, sportEvent, league]);
  return {
    matchStatusClass,
    matchStatusText,
    isOngoing,
    isFinished
  };
};
setGlobal('svs.components.poolBetting.hooks.useGetMatchstatus', useGetMatchstatus);

 })(window);