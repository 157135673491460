(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/definitions/bomben.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/definitions/bomben.js');
"use strict";

let EventTypeId;
let productIds;
let constants;
let brandMapping;
let SportTypes;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  constants = require('../constants.es6');
  productIds = trinidad.components.require('utils', 'products').productIds;
  EventTypeId = require('../../../../../sportinfo/common/assets/javascripts/constants.es6').EventTypeId;
  brandMapping = require('../../../../../lb-utils/brand-mapping/assets/javascripts/brand-mapping.es6');
  SportTypes = trinidad.components.require('sport', 'sport-types').api;
} else {
  EventTypeId = svs.components.sportinfo.common.constants.EventTypeId;
  productIds = svs.utils.products.productIds;
  constants = svs.components.tipsen.engine.constants;
  brandMapping = svs.components.lbUtils.brandMapping.fn;
  SportTypes = svs.components.sport.sportTypes.SportTypes;
}
const bombenDefinition = {
  outcomes: {
    eventTypeId: EventTypeId.RESULT,
    outcomeCount: 11,
    outcomeGroupCount: 2,
    outcomeHeatmap: {
      [SportTypes.Undefined]: {
        minValue: 0,
        minStartValue: 0,
        maxValue: 10,
        maxStartValue: 10
      },
      [SportTypes.Soccer]: {
        minValue: 0,
        minStartValue: 0,
        maxValue: 10,
        maxStartValue: 10
      },
      [SportTypes.Hockey]: {
        minValue: 0,
        minStartValue: 0,
        maxValue: 10,
        maxStartValue: 10
      },
      [SportTypes.Bandy]: {
        minValue: 0,
        minStartValue: 0,
        maxValue: 10,
        maxStartValue: 10
      },
      [SportTypes.Floorball]: {
        minValue: 0,
        minStartValue: 0,
        maxValue: 10,
        maxStartValue: 10
      }
    }
  },
  competitions: [],
  rSystems: [],
  uSystems: [],
  reduction: [constants.ReductionScore.HOME, constants.ReductionScore.DRAW, constants.ReductionScore.AWAY],
  systems: [constants.systems.SYSTEM_MSYS, constants.systems.SYSTEM_RSYS],
  betAmounts: [1, 2, 5, 10],
  maxBetAmount: 500,
  weeks: [1],
  sportkryssFactors: [0],
  sportkryssBase: 10,
  addonPixSize: [0],
  maxSystemSize: 0,
  drawProductIds: [productIds.BOMBEN],
  xpertMsys: [],
  xpertMsysSpecialValues: [],
  quickPlayPixSizes: [24, 40, 60, 100, 150, 200, 300, 400, 500, 750, 1000, 1500],
  displayName: brandMapping.getProductDisplayName(productIds.BOMBEN),
  brandName: brandMapping.getSiteBrand(productIds.BOMBEN),
  brandLogo: brandMapping.getProductBrand(productIds.BOMBEN),
  getDisplayNameByDraw: draw => brandMapping.getProductDisplayNameByDraw(draw, productIds.BOMBEN),
  getBrandNamesByDraw: draw => brandMapping.getProductBrandsByDraw(draw, productIds.BOMBEN),
  getBrandLogoByDraw: draw => brandMapping.getProductBrandByDraw(draw, productIds.BOMBEN),
  getBrandMenuColorByDraw: draw => brandMapping.getProductBrandMenuColorByDraw(draw, productIds.BOMBEN),
  maxExternalSystemSize: 2000,
  features: {
    marketplace: true,
    singles: false
  },
  getRelatedProduct: () => null,
  getTransfer: () => null,
  pixSystems: [],
  minGroupAmount: 10,
  minStake: 1,
  hasGuaranteedJackpot: false,
  hasJackpot: false,
  tipsinfoSupport: [constants.TipsinfoTypes.SvenskaFolket, constants.TipsinfoTypes.Analys, constants.TipsinfoTypes.Matchstart],
  tipsinfoSupportResult: [constants.TipsinfoTypes.SvenskaFolket],
  tipsinfoSupportMyBets: [constants.TipsinfoTypes.SvenskaFolket],
  loadDrawsApiPath: 'bomben',
  moduleEngine: 'bomben',
  defaultCouponValues: {
    betAmount: 1,
    reduceModeCount: undefined,
    pixSize: 40
  },
  pixType: 'BombenMatchen',
  pixSystem: '',
  externalChunkSize: {
    SROW: 3600 * 2,
    MSYS: 1,
    SROW_MARKETPLACE_GROUP: 3300 * 2,
    MSYS_MARKETPLACE_GROUP: 1
  },
  couponSettings: [],
  maxWagerAmount: 20000,
  maxSelectionOutcomeGroup: 0
};
if (svs.isServer) {
  module.exports = bombenDefinition;
} else {
  setGlobal('svs.components.tipsen.engine.definitions.bomben', bombenDefinition);
}

 })(window);