(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/configs/assets/javascripts/content-config.js') >= 0) return;  svs.modules.push('/components/sportinfo/configs/assets/javascripts/content-config.js');
"use strict";


const {
  matchState: {
    SportinfoMatchState: MatchState
  }
} = svs.components.sportinfo.common;
const {
  SportTypes
} = svs.components.sport.sportTypes;
const {
  fallbackConfig,
  sportTypeOverrides: {
    trottingConfig,
    soccerConfig,
    tennisConfig,
    handballConfig,
    iceHockeyConfig,
    basketballConfig
  },
  productOverrides: {
    fulltraffConfig,
    maltipsetConfig
  },
  providerOverrides: {
    sweHockeyConfig
  }
} = svs.components.sportinfo.configs.sportConfigs;
const productIds = svs.utils.products.productIds;
const logger = svs.core.log.getLogger('sportinfo:content-config');
const getSportTypeConfig = sportType => {
  switch (sportType) {
    case SportTypes.Soccer:
      return soccerConfig;
    case SportTypes.Hockey:
      return iceHockeyConfig;
    case SportTypes.Basketball:
      return basketballConfig;
    case SportTypes.Handball:
      return handballConfig;
    case SportTypes.Tennis:
    case SportTypes.AmericanFootball:
      return tennisConfig;
    case SportTypes.Trotting:
      return trottingConfig;
    default:
      return null;
  }
};

const getProductConfig = productId => {
  switch (productId) {
    case productIds.FULLTRAFF:
      return fulltraffConfig;
    case productIds.MALTIPSET:
      return maltipsetConfig;
    default:
      return null;
  }
};
const getConfigForProductOrSportType = (productId, sportType, providerIds) => {
  if (providerIds !== null && providerIds !== void 0 && providerIds.SweHockey) {
    return sweHockeyConfig;
  }
  if (productId) {
    const productConfig = getProductConfig(productId);
    if (productConfig) {
      return productConfig;
    }
  }
  if (sportType) {
    const sportTypeConfig = getSportTypeConfig(sportType);
    if (sportTypeConfig) {
      return sportTypeConfig;
    }
  }
  return fallbackConfig;
};

const getContentForMatchState = (content, matchState) => Object.keys(content).reduce((acc, menuItemName) => {
  if (content[menuItemName][matchState]) {
    acc[menuItemName] = content[menuItemName][matchState];
  }
  return acc;
}, {});
const getContent = _ref => {
  let {
    sportType,
    matchState = MatchState.NotStarted,
    productId,
    providerIds
  } = _ref;
  const config = getConfigForProductOrSportType(productId, sportType, providerIds);
  return getContentForMatchState(config.content, matchState);
};
const getMenuConfig = _ref2 => {
  let {
    sportType,
    productId,
    providerIds,
    matchState = MatchState.NotStarted
  } = _ref2;
  const config = getConfigForProductOrSportType(productId, sportType, providerIds);
  const filteredMenuItems = [];
  const menuConfig = config.menuItems[matchState];
  filteredMenuItems.push(...menuConfig);
  logger.debug('### Used config: ', config.config);
  return filteredMenuItems;
};
const getDefaultRoute = _ref3 => {
  var _defaultRoutes$matchS;
  let {
    sportType,
    productId,
    providerIds,
    matchState
  } = _ref3;
  const {
    defaultRoutes
  } = getConfigForProductOrSportType(productId, sportType, providerIds);
  if (!defaultRoutes) {
    return undefined;
  }
  return (_defaultRoutes$matchS = defaultRoutes[matchState]) !== null && _defaultRoutes$matchS !== void 0 ? _defaultRoutes$matchS : undefined;
};
setGlobal('svs.components.sportinfo.configs.ContentConfig', {
  getMenuConfig,
  getContent,
  getDefaultRoute
});

 })(window);