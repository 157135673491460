(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/sportinfo-content-view/assets/javascripts/sportinfo-content-view.js') >= 0) return;  svs.modules.push('/components/sportinfo/sportinfo-content-view/assets/javascripts/sportinfo-content-view.js');
"use strict";


const {
  ContentSectionTitle
} = svs.components.sportinfo.sportinfoContentView;
const {
  config
} = svs.components.sportinfo.sportinfoContentView;
const SportinfoContentView = _ref => {
  let {
    branding,
    className,
    content,
    drawNumber,
    eventNumber,
    eventTypeId,
    FallbackComp,
    matchId,
    productId,
    setCoupon,
    sportType,
    theme = 'LIGHT',
    lazyComponentsMap,
    externalId
  } = _ref;
  if (!content) {
    return React.createElement(FallbackComp, {
      branding: branding
    });
  }
  return content.map(contentItem => {
    const componentConfig = config[contentItem.provider](contentItem.type);
    const Component = lazyComponentsMap.get(componentConfig.component);
    const key = "".concat(contentItem.provider, "_").concat(contentItem.type);
    return React.createElement(ContentSectionTitle, {
      key: key,
      theme: theme,
      title: contentItem.title
    }, React.createElement(Component, {
      branding: branding,
      className: className,
      content: content,
      drawNumber: drawNumber,
      eventNumber: eventNumber,
      eventTypeId: eventTypeId,
      externalId: externalId,
      FallbackComp: FallbackComp,
      matchId: matchId,
      productId: productId,
      setCoupon: setCoupon,
      sportType: sportType,
      theme: theme,
      viewProps: contentItem.viewProps,
      widget: contentItem,
      widgetId: contentItem.widgetId
    }));
  });
};
setGlobal('svs.components.sportinfo.sportinfoContentView.SportinfoContentView', SportinfoContentView);

 })(window);