(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/tipsen-modal/assets/javascripts/tipsen-modal.js') >= 0) return;  svs.modules.push('/components/tipsen/tipsen-modal/assets/javascripts/tipsen-modal.js');
"use strict";


const {
  Modal
} = svs.components.lbUi.modal;
const {
  useEffect,
  useState,
  useCallback
} = React;
const {
  useBranding
} = svs.components.tipsen.hooks;
const {
  actions,
  PARAM_ACTION
} = svs.components.sport.tipsenShared;
const {
  useHistory
} = ReactRouterDOM;
const TipsenModal = React.forwardRef((_ref, ref) => {
  let {
    isOpen,
    children,
    className = '',
    Bottom = () => null,
    scrollToEventOnClose = false
  } = _ref;
  const classNames = [];
  const [el, setEl] = useState();
  const history = useHistory();
  const [productBranding] = useBranding();
  if (productBranding && productBranding.length > 0) {
    classNames.push("tipsen-modal-window--".concat(productBranding));
  }
  const setRef = useCallback(node => {
    if (!node) {
      return;
    }
    if (ref) {
      ref.current = node;
    }
    setEl(node);
  }, [ref]);
  useEffect(() => {
    var _document$querySelect, _document$querySelect2;
    if (!el) {
      return;
    }
    const topContentWrapper = ((_document$querySelect = document.querySelector('.js-top-content-wrapper')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.offsetHeight) || 0;
    const stickyBottom = ((_document$querySelect2 = document.querySelector('.sticky-bottom')) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.offsetHeight) || 0;
    const reservedHeight = topContentWrapper + stickyBottom;
    el.style.setProperty('height', "calc(100svh - ".concat(reservedHeight, "px)"));
    const tipsenRootElem = document.querySelector('#tipsen');
    const tipsenRootElemPadding = tipsenRootElem === null || tipsenRootElem === void 0 ? void 0 : tipsenRootElem.style.paddingBottom;
    if (tipsenRootElem && isOpen) {
      tipsenRootElem.style.paddingBottom = '0px';
    }
    if (!isOpen && scrollToEventOnClose) {
      const search = new URLSearchParams(location.search);
      search.set(PARAM_ACTION, actions.CLOSE_MODAL);
      history.push({
        search: "?".concat(search)
      });
    }

    const hiddenContentMainElem = tipsenRootElem === null || tipsenRootElem === void 0 ? void 0 : tipsenRootElem.querySelector('.lb-modal-hidden-content main');
    if (hiddenContentMainElem) {
      if (isOpen) {
        hiddenContentMainElem.setAttribute('inert', '');
      } else {
        hiddenContentMainElem.removeAttribute('inert');
      }
    }
    return () => {
      if (tipsenRootElem) {
        tipsenRootElem.style.paddingBottom = tipsenRootElemPadding;
      }
      if (hiddenContentMainElem) {
        hiddenContentMainElem.removeAttribute('inert');
      }
    };
  }, [isOpen, el, scrollToEventOnClose, history]);
  className && classNames.push(className);
  return React.createElement(Modal, {
    className: ['tipsen-modal-window'].concat(classNames).join(' '),
    isOpen: isOpen
  }, React.createElement("main", {
    className: "tipsen-modal-handler",
    ref: setRef
  }, children), React.createElement(Bottom, null));
});
setGlobal('svs.components.tipsen.tipsenModal.TipsenModal', TipsenModal);

 })(window);