(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/center-to-screen/assets/center-to-screen.js') >= 0) return;  svs.modules.push('/components/tipsen/center-to-screen/assets/center-to-screen.js');
"use strict";

const _excluded = ["children", "className", "isMainElem"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }

const CenterToScreen = _ref => {
  let {
      children,
      className,
      isMainElem
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  if (isMainElem) {
    return React.createElement("main", _extends({
      className: ['tipsen-center-to-screen'].concat(className !== null && className !== void 0 ? className : []).join(' ')
    }, props), children);
  }
  return React.createElement("div", _extends({
    className: ['tipsen-center-to-screen'].concat(className !== null && className !== void 0 ? className : []).join(' ')
  }, props), children);
};
setGlobal('svs.components.tipsen.centerToScreen.CenterToScreen', CenterToScreen);

 })(window);