(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/sportinfo-content-view/assets/javascripts/config.js') >= 0) return;  svs.modules.push('/components/sportinfo/sportinfo-content-view/assets/javascripts/config.js');
"use strict";


const logger = svs.core.log.getLogger('sportinfo:sportinfo-content-view');
let ContentProvider;
let SvsViewType;
let EnetPulseViewType;
const SkeletonConfigKey = svs.isServer ? require('../../../skeleton-wrapper/assets/javascripts/constants.es6') : svs.components.sportinfo.skeletonWrapper.SkeletonConfigKey;
let getSkeletonConfig;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  ({
    ContentProvider,
    SvsViewType,
    EnetPulseViewType
  } = trinidad.components.require('sportinfo', '').api.constants);
  getSkeletonConfig = () => {};
} else {
  ({
    ContentProvider,
    SvsViewType,
    EnetPulseViewType
  } = svs.components.sportinfo.common.constants);
  const {
    SkeletonConfig
  } = svs.components.sportinfo.skeletonWrapper;
  getSkeletonConfig = configKey => {
    var _SkeletonConfig$confi, _SkeletonConfig$DEFAU;
    return ((_SkeletonConfig$confi = SkeletonConfig[configKey]) === null || _SkeletonConfig$confi === void 0 ? void 0 : _SkeletonConfig$confi.skeletonConfig) || ((_SkeletonConfig$DEFAU = SkeletonConfig.DEFAULT) === null || _SkeletonConfig$DEFAU === void 0 ? void 0 : _SkeletonConfig$DEFAU.skeletonConfig);
  };
}
const config = {
  [ContentProvider.Svs]: type => {
    switch (type) {
      case SvsViewType.MatchInfo:
        return {
          component: '/cl/sportinfo/match-info',
          componentRef: () => svs.components.sportinfo.matchInfo.MatchInfo,
          skeletonConfig: () => getSkeletonConfig(SkeletonConfigKey.MATCH_INFO)
        };
      case SvsViewType.MatchInfoFulltraff:
        return {
          component: '/cl/sportinfo/match-info-fulltraff',
          componentRef: () => svs.components.sportinfo.matchInfoFulltraff.MatchInfoFulltraff,
          skeletonConfig: () => getSkeletonConfig(SkeletonConfigKey.MATCH_INFO)
        };
      case SvsViewType.News:
        return {
          component: '/cl/sportinfo/news',
          componentRef: () => svs.components.sportinfo.news.News,
          skeletonConfig: () => getSkeletonConfig(SkeletonConfigKey.TT_NEWS)
        };
      case SvsViewType.Analyze:
        return {
          component: '/cl/sportinfo/analyze',
          componentRef: () => svs.components.sportinfo.analyze.Analyze,
          skeletonConfig: () => getSkeletonConfig('DEFAULT')
        };
      default:
        logger.warn("Trying to render unspecified contentView with type: ".concat(type));
        return {
          component: '/cl/sport/tipsen-empty-notice',
          componentRef: () => svs.component.tipsenEmptyNotice.EmptyNotice,
          skeletonConfig: () => getSkeletonConfig('DEFAULT')
        };
    }
  },
  [ContentProvider.SportRadar]: () => ({
    component: '/cl/sportinfo/sportradar-view',
    componentRef: () => svs.components.sportinfo.sportradarView.SportradarView,
    skeletonConfig: type => getSkeletonConfig(type)
  }),
  [ContentProvider.SweHockey]: () => ({
    component: '/cl/sportinfo/swe-hockey-view',
    componentRef: () => svs.components.sportinfo.sweHockeyView.SweHockeyView,
    skeletonConfig: () => getSkeletonConfig('DEFAULT')
  }),
  [ContentProvider.PlayMakerGoal]: () => ({
    component: '/cl/sportinfo/playmaker-view',
    componentRef: () => svs.components.sportinfo.playmakerView.PlaymakerView,
    skeletonConfig: () => getSkeletonConfig('DEFAULT')
  }),
  [ContentProvider.PlayMakerXstat]: () => ({
    component: '/cl/sportinfo/playmaker-view',
    componentRef: () => svs.components.sportinfo.playmakerView.PlaymakerView,
    skeletonConfig: () => getSkeletonConfig('DEFAULT')
  }),
  [ContentProvider.EnetPulse]: type => {
    switch (type) {
      case EnetPulseViewType.HeadToHead:
        return {
          component: '/cl/sportinfo/enetpulse',
          componentRef: () => svs.components.sportinfo.enetpulse.EnetpulseWidget,
          skeletonConfig: () => getSkeletonConfig(SkeletonConfigKey.MATCH_INFO)
        };
      case EnetPulseViewType.TournamentStandings:
        return {
          component: '/cl/sportinfo/enetpulse',
          componentRef: () => svs.components.sportinfo.enetpulse.EnetpulseWidget,
          skeletonConfig: () => getSkeletonConfig(SkeletonConfigKey.MATCH_INFO)
        };
      case EnetPulseViewType.MatchIncidents:
        return {
          component: '/cl/sportinfo/enetpulse',
          componentRef: () => svs.components.sportinfo.enetpulse.EnetpulseWidget,
          skeletonConfig: () => getSkeletonConfig(SkeletonConfigKey.MATCH_INFO)
        };
      case EnetPulseViewType.MatchFormations:
        return {
          component: '/cl/sportinfo/enetpulse',
          componentRef: () => svs.components.sportinfo.enetpulse.EnetpulseWidget,
          skeletonConfig: () => getSkeletonConfig(SkeletonConfigKey.MATCH_INFO)
        };
      case EnetPulseViewType.WinProbability:
        return {
          component: '/cl/sportinfo/enetpulse',
          componentRef: () => svs.components.sportinfo.enetpulse.EnetpulseWidget,
          skeletonConfig: () => getSkeletonConfig(SkeletonConfigKey.MATCH_INFO)
        };
      case EnetPulseViewType.MatchDetails:
        return {
          component: '/cl/sportinfo/enetpulse',
          componentRef: () => svs.components.sportinfo.enetpulse.EnetpulseWidget,
          skeletonConfig: () => getSkeletonConfig(SkeletonConfigKey.MATCH_INFO)
        };
      case EnetPulseViewType.MatchStatistics:
        return {
          component: '/cl/sportinfo/enetpulse',
          componentRef: () => svs.components.sportinfo.enetpulse.EnetpulseWidget,
          skeletonConfig: () => getSkeletonConfig(SkeletonConfigKey.MATCH_INFO)
        };
      default:
        logger.warn("Trying to render unspecified contentView with type: ".concat(type));
        return {
          component: '/cl/sport/tipsen-empty-notice',
          componentRef: () => svs.component.tipsenEmptyNotice.EmptyNotice,
          skeletonConfig: () => getSkeletonConfig('DEFAULT')
        };
    }
  }
};
if (svs.isServer) {
  module.exports = config;
} else {
  setGlobal('svs.components.sportinfo.sportinfoContentView.config', config);
}

 })(window);