(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/horizontal-text-animation/assets/javascripts/horizontal-text-animation.js') >= 0) return;  svs.modules.push('/components/lb-ui/horizontal-text-animation/assets/javascripts/horizontal-text-animation.js');
"use strict";


const {
  useEffect,
  useRef
} = React;
const {
  clx
} = svs.components.lbUtils;
const {
  features,
  useMediaQuery
} = svs.components.lbUtils.useMediaQuery;
const HorizontalTextAnimation = _ref => {
  let {
    children,
    classNames
  } = _ref;
  const shouldReduceMotion = useMediaQuery(features.PREFERS_REDUCED_MOTION);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  useEffect(() => {
    var _containerRef$current, _contentRef$current;
    const containerWidth = ((_containerRef$current = containerRef.current) === null || _containerRef$current === void 0 ? void 0 : _containerRef$current.clientWidth) || 0;
    const contentWidth = ((_contentRef$current = contentRef.current) === null || _contentRef$current === void 0 ? void 0 : _contentRef$current.scrollWidth) || 0;
    const xAnimationLengthForExtraMoney = contentWidth - containerWidth;
    let gsapTween;
    if (!shouldReduceMotion && xAnimationLengthForExtraMoney > 0) {
      gsapTween = gsap.to('.horizontal_text_animation__content', {
        x: -xAnimationLengthForExtraMoney,
        duration: 5,
        repeat: -1,
        yoyo: true,
        ease: 'slow',
        yoyoEase: true
      });
    }
    return () => {
      var _gsapTween;
      (_gsapTween = gsapTween) === null || _gsapTween === void 0 || _gsapTween.kill();
    };
  }, [shouldReduceMotion]);
  return React.createElement("div", {
    className: clx('horizontal_text_animation', {
      'horizontal_text_animation--scrollable': shouldReduceMotion
    }, classNames),
    ref: containerRef
  }, React.createElement("div", {
    className: "horizontal_text_animation__content",
    ref: contentRef
  }, children));
};
setGlobal('svs.components.lbUi.HorizontalTextAnimation', HorizontalTextAnimation);

 })(window);