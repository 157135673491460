(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/europatipset/assets/javascripts/my-bets-route.js') >= 0) return;  svs.modules.push('/modules/europatipset/assets/javascripts/my-bets-route.js');
"use strict";


const {
  ProtectedRoute
} = svs.components.lbUtils.protectedRoute;
const {
  Route,
  Switch
} = ReactRouterDOM;
const {
  tipsenLazy
} = svs.components.tipsen.tipsenLazy;
const {
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;

const LazyRouteMyBets = tipsenLazy('route-my-bets', () => svs.europatipset.components.RouteMyBets, {
  fallback: () => null
});

const LazyRouteMyBetsWager = tipsenLazy('route-my-bets-wager', () => svs.europatipset.components.routeMyBetsWager.RouteMyBetsWager, {
  fallback: () => null
});
const allowedRoles = ['Player', 'CustomerService'];
const MyBetsRoute = () => React.createElement(ProtectedRoute, {
  roles: allowedRoles
}, React.createElement(Switch, null, React.createElement(Route, {
  component: LazyRouteMyBetsWager,
  path: "/".concat(TipsenModuleGameUrl.MY_BETS2, "/:wagerId(\\d+)")
}), React.createElement(Route, {
  component: LazyRouteMyBets
})));
setGlobal('svs.europatipset.MyBetsRoute', MyBetsRoute);

 })(window);