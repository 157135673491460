(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/trend/assets/javascripts/trend.js') >= 0) return;  svs.modules.push('/components/lb-ui/trend/assets/javascripts/trend.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  useRef,
  useEffect,
  useReducer
} = React;
const {
  useSelector
} = ReactRedux;
const {
  isTextOrNumber
} = svs.components.lbUi.trend.utils;
const Trends = {
  UP: 'up',
  DOWN: 'down',
  NEUTRAL: 'neutral'
};
const Actions = {
  NEW_UNIQUE_ID: 'new_unique_id',
  NEW_VALUE: 'new_value'
};
const TrendChangeTimeout = 7 * 1000;
const trendReducer = (state, action) => {
  switch (action.type) {
    case Actions.NEW_UNIQUE_ID:
    case Actions.NEW_VALUE:
      return _objectSpread(_objectSpread({}, state), action.payload);
    default:
      return state;
  }
};
const Trend = _ref => {
  let {
    uniqueId,
    postfixSymbol,
    selectorFunction,
    role = undefined
  } = _ref;
  const timer = useRef();
  const value = useSelector(selectorFunction);
  const [state, dispatch] = useReducer(trendReducer, {
    uniqueId,
    value
  });
  useEffect(() => {
    const resetLastStatValue = () => {
      dispatch({
        type: Actions.NEW_VALUE,
        payload: {
          value
        }
      });
    };
    timer.current = setTimeout(resetLastStatValue, TrendChangeTimeout);
    return () => {
      clearTimeout(timer.current);
      resetLastStatValue();
    };
  }, [value]);
  useEffect(() => {
    if (state.uniqueId !== uniqueId) {
      dispatch({
        type: Actions.NEW_UNIQUE_ID,
        payload: {
          uniqueId,
          value
        }
      });
      clearTimeout(timer.current);
    }
  }, [uniqueId, value, state.uniqueId]);
  let trend = Trends.NEUTRAL;
  if (uniqueId === state.uniqueId) {
    if (value > state.value) {
      trend = Trends.UP;
    } else if (value < state.value) {
      trend = Trends.DOWN;
    }
  }
  const presentableValue = isTextOrNumber(value) ? "".concat(value).concat(postfixSymbol !== null && postfixSymbol !== void 0 ? postfixSymbol : '') : '-';
  return React.createElement("div", {
    className: "stat-trend stat-trend-".concat(trend),
    role: role
  }, presentableValue);
};
setGlobal('svs.components.lbUi.trend.Trend', Trend);

 })(window);