(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-additions/assets/javascripts/coupon-additions.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-additions/assets/javascripts/coupon-additions.js');
"use strict";


const {
  useSelector
} = ReactRedux;
const {
  useComponentFromRegistry
} = svs.components.tipsen.componentRegistry;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  selectSportkryssFactors,
  selectWeeks,
  selectBetAmounts
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  isMarketplaceWallet
} = svs.components.tipsen.wallet.walletHelpers;
const {
  WALLET_SELFSERVICE
} = svs.components.tipsen.wallet.walletType;
const {
  WALLET_ID_PLAYER
} = svs.components.tipsen.wallet.walletId;
const {
  selectActiveWallet,
  useWallets
} = svs.components.tipsen.walletSelectors;
const {
  WalletComponent,
  WalletAddition
} = svs.components.tipsen.walletComponent;
const {
  selectIsSportkryssSupported
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  Sportkryss,
  BetAmounts,
  AddonPix
} = svs.components.tipsen.couponAdditionsComponents;
const {
  useDefinition
} = svs.components.tipsen.engine.utils;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  feature
} = svs.core.detect;
const {
  productIds
} = svs.utils.products;
const CouponAdditions = _ref => {
  let {
    hasWeeksEnabled = true,
    hasBetAmountEnabled = true,
    className = ''
  } = _ref;
  const Weeks = useComponentFromRegistry('coupon-multiple-additions');
  const couponId = useCouponId();
  const sportkryssFactors = useSelector(selectSportkryssFactors);
  const draw = useCurrentDraw();
  const productId = draw.productId;
  const isMultiDrawsDisabled = feature('ft-hvs-disable-multidraws');
  let weeks = useSelector(selectWeeks);
  if (productId === productIds.FULLTRAFF && isMultiDrawsDisabled) {
    weeks = [1];
  }
  const betAmounts = useSelector(selectBetAmounts);
  const wallet = useSelector(selectActiveWallet);
  const sportkryssSupported = useSelector(state => selectIsSportkryssSupported(state, couponId));
  const wallets = useWallets();
  const isSelfserviceWallet = wallet && WALLET_SELFSERVICE === wallet.type;
  const hasOnlyPlayerWallet = wallets.length === 1 && wallets[0].id === WALLET_ID_PLAYER;
  const isActiveMarketplaceWallet = isMarketplaceWallet(wallet);
  const showSportKryss = sportkryssFactors.length > 1 && sportkryssSupported;
  const showWeeks = weeks.length > 1 && !isActiveMarketplaceWallet && hasWeeksEnabled;
  const showBetAmount = betAmounts.length > 1 && hasBetAmountEnabled;
  const showWallet = !isSelfserviceWallet && wallets.length > 0 && !hasOnlyPlayerWallet;
  const drawNumber = draw.drawNumber;
  const definition = useDefinition();
  const type = 'pix';
  const addonPixProductId = definition.getRelatedProduct(type, productId);
  const hasAddonPix = Boolean(!isActiveMarketplaceWallet && addonPixProductId);
  const shouldShowAdditions = showSportKryss || showWeeks || showBetAmount || showWallet;
  if (!shouldShowAdditions) {
    return null;
  }
  const classNames = ['tipsen-additions'];
  classNames.push(className);
  return React.createElement("section", {
    "aria-labelledby": "tipsen-additions-heading",
    className: classNames.join(' ')
  }, React.createElement("h2", {
    className: "tipsen-header f-center f-medium",
    id: "tipsen-additions-heading"
  }, "Tillval"), React.createElement("div", {
    className: "tipsen-additions-grid"
  }, showSportKryss && React.createElement(Sportkryss, null), showWeeks && React.createElement(Weeks, null), showBetAmount && React.createElement(BetAmounts, null), hasAddonPix && React.createElement(AddonPix, {
    drawNumber: drawNumber,
    pixProductId: addonPixProductId,
    productId: productId
  }), showWallet && React.createElement(WalletComponent, {
    Component: WalletAddition
  })));
};
setGlobal('svs.components.tipsen.couponAdditions.CouponAdditions', CouponAdditions);

 })(window);