(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/configs/assets/javascripts/overrides/product/maltipset.js') >= 0) return;  svs.modules.push('/components/sportinfo/configs/assets/javascripts/overrides/product/maltipset.js');
"use strict";


const newEnetpulseWidgets = svs.core.detect.feature('ft-enetpulse-new');
const {
  matchState: {
    SportinfoMatchState: MatchState
  },
  constants: {
    SportinfoPathNames,
    SportinfoSportradarWidgets: SportradarWidgets,
    EnetPulseViewType,
    SvsViewType
  }
} = svs.components.sportinfo.common;
const {
  ContentProvider,
  EnetpulseWidgets
} = svs.components.sportinfo.common.constants;
const {
  ConfigBuilder
} = svs.components.sportinfo.configBuilder;
const {
  menuItems,
  content,
  defaultRoutes
} = new ConfigBuilder().addTab(SportinfoPathNames.MatchInfo, tab => {
  tab.setAll([{
    type: SvsViewType.MatchInfo,
    provider: ContentProvider.Svs
  }]).prepend(MatchState.Ongoing, [{
    type: SportradarWidgets.LiveMatchTracker,
    provider: ContentProvider.SportRadar
  }]);
}).addTab(SportinfoPathNames.Statistic, tab => {
  tab.set(MatchState.NotStarted, [{
    type: SportradarWidgets.HeadToHead,
    provider: ContentProvider.SportRadar,
    viewProps: {
      components: ['teamstats']
    }
  }]).setMany([MatchState.Ongoing, MatchState.Finished], [{
    type: SportradarWidgets.Scoreboard,
    provider: ContentProvider.SportRadar,
    viewProps: {
      expandedGoalScorers: true
    }
  }, {
    type: SportradarWidgets.GeneralStatistics,
    provider: ContentProvider.SportRadar
  }]);
}).addTab(SportinfoPathNames.PlayMakerXstat, tab => {
  tab.setAll([{
    type: SvsViewType.PlayMakerXstat,
    provider: ContentProvider.PlayMakerXstat,
    viewProps: {
      isXstat: true
    }
  }]);
}).addTab(SportinfoPathNames.Mutuals, tab => {
  tab.setMany([MatchState.Ongoing, MatchState.NotStarted], [{
    type: SportradarWidgets.MatchPreview,
    provider: ContentProvider.SportRadar,
    viewProps: {
      disableMoreStats: true,
      disableExpandButton: true
    }
  }]);
}).addTab(SportinfoPathNames.News, tab => {
  tab.setAll([{
    type: SvsViewType.News,
    provider: ContentProvider.Svs
  }]);
}).addTab(SportinfoPathNames.Table, tab => {
  tab.setAll([{
    type: SportradarWidgets.LiveTable,
    provider: ContentProvider.SportRadar,
    viewProps: {
      showOnlySelectedGroup: true
    }
  }]);
}).addTab(SportinfoPathNames.Lineup, tab => {
  tab.setAll([{
    type: SportradarWidgets.MatchLineups,
    provider: ContentProvider.SportRadar
  }]);
}).setDefaultRoutes(SportinfoPathNames.Statistic, [MatchState.Finished]).build();
const {
  menuItems: enetpulseMenuItems,
  content: enetpulseContent,
  defaultRoutes: enetpulseDefaultRoutes
} = new ConfigBuilder().addTab(SportinfoPathNames.MatchInfo, tab => {
  tab.setAll([{
    type: SvsViewType.MatchInfoFulltraff,
    provider: ContentProvider.Svs
  }]);
}).addTab(SportinfoPathNames.Statistic, tab => {
  tab.set(MatchState.NotStarted, [{
    type: EnetPulseViewType.HeadToHead,
    provider: ContentProvider.EnetPulse,
    widgetId: EnetpulseWidgets.HeadToHead,
    viewProps: {
      options: {
        wv: 4,
        lng: 'sv',
        el_limit: 5,
        sh_h2h_s_f: 'yes',
        h2h_sh_m: 'yes'
      }
    }
  }, {
    type: EnetPulseViewType.WinProbability,
    provider: ContentProvider.EnetPulse,
    widgetId: EnetpulseWidgets.WinProbability,
    viewProps: {
      options: {
        lng: 'sv'
      }
    }
  }]).setMany([MatchState.Ongoing, MatchState.Finished], [{
    type: EnetPulseViewType.MatchIncidents,
    provider: ContentProvider.EnetPulse,
    title: 'Matchhändelser',
    widgetId: EnetpulseWidgets.MatchIncidents,
    viewProps: {
      options: {
        lng: 'sv'
      }
    }
  }]).prependAll([{
    provider: ContentProvider.PlayMakerGoal,
    viewProps: {
      isXstat: false
    }
  }]);
}).addTab(SportinfoPathNames.PlayMakerXstat, tab => {
  tab.setAll([{
    type: SvsViewType.PlayMakerXstat,
    provider: ContentProvider.PlayMakerXstat,
    viewProps: {
      isXstat: true
    }
  }]);
}).addTab(SportinfoPathNames.News, tab => {
  tab.setAll([{
    type: SvsViewType.News,
    provider: ContentProvider.Svs
  }]);
}).addTab(SportinfoPathNames.Table, tab => {
  tab.setAll([{
    type: EnetPulseViewType.TournamentStandings,
    provider: ContentProvider.EnetPulse,
    widgetId: EnetpulseWidgets.TournamentStandings,
    viewProps: {
      options: {
        lng: 'sv'
      }
    }
  }]);
}).addTab(SportinfoPathNames.Lineup, tab => {
  tab.setAll([{
    type: EnetPulseViewType.MatchFormations,
    provider: ContentProvider.EnetPulse,
    widgetId: EnetpulseWidgets.MatchFormations,
    viewProps: {
      options: {
        lng: 'sv'
      }
    }
  }]);
}).setDefaultRoutes(SportinfoPathNames.Statistic, [MatchState.Finished]).build();
setGlobal('svs.components.sportinfo.configs.sportConfigs.productOverrides', {
  maltipsetConfig: newEnetpulseWidgets ? {
    menuItems: enetpulseMenuItems,
    content: enetpulseContent,
    defaultRoutes: enetpulseDefaultRoutes,
    config: 'maltipset' 
  } : {
    menuItems,
    content,
    defaultRoutes,
    config: 'maltipset' 
  }
});

 })(window);