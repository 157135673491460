(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/product-header/assets/javascripts/product-header.js') >= 0) return;  svs.modules.push('/components/lb-ui/product-header/assets/javascripts/product-header.js');
"use strict";


const Brand = svs.ui.ReactBrand;
const ProductHeader = _ref => {
  let {
    branding,
    rightButton = null,
    leftButton = null,
    size = 100
  } = _ref;
  return React.createElement("div", {
    className: "product-header-react product-header-branding-".concat(branding)
  }, React.createElement("div", {
    className: "product-header-clip"
  }, React.createElement("div", null, leftButton && leftButton), React.createElement("div", null, React.createElement(Brand, {
    brand: branding,
    emblem: true,
    size: size
  })), React.createElement("div", null, rightButton && rightButton)));
};
setGlobal('svs.components.lbUi.productHeader.ProductHeader', ProductHeader);

 })(window);