(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_actions/quickplay/assets/javascripts/init-actions.js') >= 0) return;  svs.modules.push('/components/banner_actions/quickplay/assets/javascripts/init-actions.js');
"use strict";


const log = new svs.core.log.Logger('component:banner_actions:quickplay');
const {
  dialog
} = svs.ui;
const {
  getPixHandler
} = svs.components.sport.pixHandler;
let size = 300;

function _getWidgetPosition(_ref) {
  let {
    element,
    allWidgetsOnPage
  } = _ref;
  const listOfElementAndAllItsParents = [];
  for (let node = element; node; node = node.parentNode) {
    listOfElementAndAllItsParents.push(node);
  }
  for (let index = 0; index < allWidgetsOnPage.length; index++) {
    const currWidget = allWidgetsOnPage[index];
    if (listOfElementAndAllItsParents.some(node => node === currWidget)) {
      return index + 1;
    }
  }
  return 0;
}
function _loadAndOpenQuickplayModal2(_ref2) {
  var _svs$components;
  let {
    branding,
    productId,
    drawNumber,
    systemCost,
    systemType,
    rowPrice,
    productSizes
  } = _ref2;
  svs.ui.loader.show();
  const parseToFloat = item => parseFloat(String(item).replace(',', '.'));
  let sizes = [];
  if (productSizes !== null && productSizes !== void 0 && productSizes.length) {
    if (typeof productSizes === 'string') {
      sizes = productSizes.split(',').map(p => parseFloat(p));
    } else {
      sizes = productSizes;
    }
  }
  const _showModalOrErrorAlert2 = error => {
    let hasError = Boolean(error);
    if (hasError) {
      log.info('Failed to lazy-load quickplay-modal', error.message);
    } else {
      try {
        svs.components.sport.quickplayModal2.init({
          branding,
          productId,
          drawNumber,
          systemCost: parseToFloat(systemCost),
          systemType,
          rowPrice: parseToFloat(rowPrice),
          productSizes: sizes
        });
      } catch (newError) {
        hasError = true;
        log.error('Error during init of quickplay-modal', newError.message);
      }
    }
    svs.ui.loader.hide();
    if (hasError) {
      dialog.api.add(new dialog.Confirm({
        icon: 'help-2',
        actions: [{
          title: 'Okej'
        }],
        area: dialog.area.POPUP,
        autoClose: dialog.autoClose.MEDIUM,
        branding: dialog.branding.SPORT,
        title: 'Kunde inte ladda spelet, försök igen senare'
      }));
    }
  };
  if ((_svs$components = svs.components) !== null && _svs$components !== void 0 && (_svs$components = _svs$components.sport) !== null && _svs$components !== void 0 && _svs$components.quickplayModal2) {
    _showModalOrErrorAlert2(null);
  } else {
    svs.core.module.load('/cl/sport/react:quickplay-modal2', null, _showModalOrErrorAlert2, false);
  }
}

async function keepModalOpen() {
  var _pixHandler$keepOpenI;
  const pixHandler = getPixHandler({
    isPixMix: false
  });
  if ((_pixHandler$keepOpenI = pixHandler.keepOpenItem) !== null && _pixHandler$keepOpenI !== void 0 && _pixHandler$keepOpenI.pixData) {
    const {
      productId,
      systemCost,
      systemType,
      rowPrice,
      drawNumber,
      productSizes,
      branding
    } = pixHandler.keepOpenItem.pixData;
    const previousURL = pixHandler.keepOpenItem.url;
    pixHandler.unsetKeepOpenItem();
    if (previousURL === window.location.href) {
      _loadAndOpenQuickplayModal2({
        branding,
        productId,
        drawNumber,
        systemCost,
        rowPrice,
        productSizes,
        systemType
      });
    }
  }
}

function clickEvent(event) {
  var _element$classList;
  const element = event.target.closest('.js-banner-actions-quickplay');
  if (!element || !((_element$classList = element.classList) !== null && _element$classList !== void 0 && _element$classList.contains('js-banner-actions-quickplay'))) {
    return;
  }
  const allWidgetsOnPage = document.getElementsByClassName('widget');
  const {
    trackingString,
    branding,
    productId,
    drawNumber,
    systemCost,
    rowPrice,
    productSizes,
    systemType
  } = element.dataset;
  const inputFormatChecks = {
    branding: Boolean(branding),
    drawNumber: !drawNumber || !/\D/.test(drawNumber),
    productId: Boolean(productId),
    systemCost: Boolean(systemCost),
    rowPrice: Boolean(rowPrice)
  };
  if (Object.values(inputFormatChecks).some(isCorrect => !isCorrect)) {
    return;
  }
  if (trackingString) {
    const widgetPosition = _getWidgetPosition({
      element,
      allWidgetsOnPage
    });
    svs.components.analytics.trackPromoClick({
      name: trackingString,
      position: widgetPosition
    });
  }
  _loadAndOpenQuickplayModal2({
    branding,
    productId,
    drawNumber,
    systemCost,
    rowPrice,
    productSizes,
    systemType
  });
}

function resizeEvent(event) {
  const cs = event.target.innerWidth > 1355 ? 300 : 100;
  const allMainButtons = document.querySelectorAll('.products-grid-flexible-item .banner-griditem-quickplay .banner-main-button');
  if (cs !== size) {
    [...allMainButtons].forEach(el => {
      el.classList.remove("btn-".concat(size));
      el.classList.add("btn-".concat(cs));
    });
    size = cs;
  }
}

function removeClickEvent() {
  if (document.body.classList.contains('has-banner-quickplay-clicks')) {
    document.body.classList.remove('has-banner-quickplay-clicks');
    document.body.removeEventListener('click', clickEvent);
    window.removeEventListener('resize', resizeEvent);
  }
}

function addClickEvents() {
  if (document.body.classList.contains('has-banner-quickplay-clicks')) {
    log.debug('we have already setup the banner quickplay ad');
    return;
  }
  removeClickEvent();
  document.body.classList.add('has-banner-quickplay-clicks');
  document.body.addEventListener('click', clickEvent);
  window.addEventListener('resize', resizeEvent);
  resizeEvent({
    target: {
      innerWidth: window.innerWidth
    }
  });
}

setGlobal('svs.banner_actions.quickplay.initActions', {
  addClickEvents,
  keepModalOpen,
  removeClickEvent
});

 })(window);