(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/selectors/engine-selectors.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/selectors/engine-selectors.js');
"use strict";

var _svs$core$config$data, _svs$core$config$data2;
const {
  selectCompetitionDetails,
  selectActiveCompetitionId
} = svs.components.marketplaceCompetition.services.selectors.competitionSelectors;
const {
  SYSTEM_MSYS,
  SYSTEM_RSYS
} = svs.components.tipsen.engine.constants.systems;
const {
  EventTypeId
} = svs.components.sportinfo.common.constants;
const PixSystem = svs.components.tipsen.engine.constants.PixSystem;
const {
  selectEngineDefinition
} = svs.components.tipsen.engine.selectors;
const {
  userSession
} = svs.core;
const DEFAULT_EMPTY_ARRAY = Object.freeze([]);
const TipsinfoTypes = svs.components.tipsen.engine.constants.TipsinfoTypes;
const {
  pageTypes
} = svs.component.tipsen.renderContext.constants;
const isSelfService = (_svs$core$config$data = (_svs$core$config$data2 = svs.core.config.data.svsconfig) === null || _svs$core$config$data2 === void 0 ? void 0 : _svs$core$config$data2.isSelfService) !== null && _svs$core$config$data !== void 0 ? _svs$core$config$data : false;
const isRetailer = userSession.hasRole(userSession.roles.RETAILER);
const selectBetAmounts = state => {
  var _selectEngineDefiniti;
  return (_selectEngineDefiniti = selectEngineDefinition(state).betAmounts) !== null && _selectEngineDefiniti !== void 0 ? _selectEngineDefiniti : DEFAULT_EMPTY_ARRAY;
};
const selectSupportsCompetitions = state => {
  var _selectEngineDefiniti2, _selectEngineDefiniti3;
  return (_selectEngineDefiniti2 = (_selectEngineDefiniti3 = selectEngineDefinition(state).competitions) === null || _selectEngineDefiniti3 === void 0 ? void 0 : _selectEngineDefiniti3.length) !== null && _selectEngineDefiniti2 !== void 0 ? _selectEngineDefiniti2 : 0;
};
const selectRSystems = state => {
  var _selectEngineDefiniti4;
  return (_selectEngineDefiniti4 = selectEngineDefinition(state).rSystems) !== null && _selectEngineDefiniti4 !== void 0 ? _selectEngineDefiniti4 : DEFAULT_EMPTY_ARRAY;
};
const selectUSystems = state => {
  var _selectEngineDefiniti5;
  return (_selectEngineDefiniti5 = selectEngineDefinition(state).uSystems) !== null && _selectEngineDefiniti5 !== void 0 ? _selectEngineDefiniti5 : DEFAULT_EMPTY_ARRAY;
};
const selectSystemTypes = state => {
  var _selectEngineDefiniti6;
  return (_selectEngineDefiniti6 = selectEngineDefinition(state).systems) !== null && _selectEngineDefiniti6 !== void 0 ? _selectEngineDefiniti6 : DEFAULT_EMPTY_ARRAY;
};
const selectReduction = state => {
  var _selectEngineDefiniti7;
  return (_selectEngineDefiniti7 = selectEngineDefinition(state).reduction) !== null && _selectEngineDefiniti7 !== void 0 ? _selectEngineDefiniti7 : DEFAULT_EMPTY_ARRAY;
};
const selectReductionSum = state => {
  const reduction = selectReduction(state);
  return reduction.reduce((sum, r) => r + sum, 0);
};
const selectWeeks = state => {
  var _selectEngineDefiniti8;
  const activeCompetitionId = selectActiveCompetitionId(state);
  const competitionDetails = selectCompetitionDetails(state, activeCompetitionId);
  if (competitionDetails) {
    return DEFAULT_EMPTY_ARRAY;
  }
  return (_selectEngineDefiniti8 = selectEngineDefinition(state).weeks) !== null && _selectEngineDefiniti8 !== void 0 ? _selectEngineDefiniti8 : DEFAULT_EMPTY_ARRAY;
};
const selectAddonPixSizes = state => {
  var _selectEngineDefiniti9;
  return (_selectEngineDefiniti9 = selectEngineDefinition(state).addonPixSize) !== null && _selectEngineDefiniti9 !== void 0 ? _selectEngineDefiniti9 : DEFAULT_EMPTY_ARRAY;
};
const selectSportkryssFactors = state => {
  var _selectEngineDefiniti10;
  return (_selectEngineDefiniti10 = selectEngineDefinition(state).sportkryssFactors) !== null && _selectEngineDefiniti10 !== void 0 ? _selectEngineDefiniti10 : DEFAULT_EMPTY_ARRAY;
};
const selectSportkryssBase = state => {
  var _selectEngineDefiniti11;
  return (_selectEngineDefiniti11 = selectEngineDefinition(state).sportkryssBase) !== null && _selectEngineDefiniti11 !== void 0 ? _selectEngineDefiniti11 : 0;
};
const selectDrawProductIds = state => {
  var _selectEngineDefiniti12;
  return (_selectEngineDefiniti12 = selectEngineDefinition(state).drawProductIds) !== null && _selectEngineDefiniti12 !== void 0 ? _selectEngineDefiniti12 : DEFAULT_EMPTY_ARRAY;
};
const selectBrandName = state => selectEngineDefinition(state).brandName;
const selectDisplayName = state => selectEngineDefinition(state).displayName;
const selectXpertMsys = state => {
  var _selectEngineDefiniti13;
  const activeCompetitionId = selectActiveCompetitionId(state);
  const competitionDetails = selectCompetitionDetails(state, activeCompetitionId);
  if (competitionDetails && competitionDetails.systemType === SYSTEM_MSYS) {
    return [competitionDetails.systemNumber];
  }
  return (_selectEngineDefiniti13 = selectEngineDefinition(state).xpertMsys) !== null && _selectEngineDefiniti13 !== void 0 ? _selectEngineDefiniti13 : DEFAULT_EMPTY_ARRAY;
};
const selectXpertMsysSpecialValues = state => selectEngineDefinition(state).xpertMsysSpecialValues;
const selectQuickPlayPixSizes = state => selectEngineDefinition(state).quickPlayPixSizes;
const selectDefinitionId = state => state.Module.engine;
const selectMaxExternalSystemSize = state => selectEngineDefinition(state).maxExternalSystemSize;
const selectMinStake = state => selectEngineDefinition(state).minStake;
const selectFeatureStandard = state => {
  var _selectEngineDefiniti14, _selectEngineDefiniti15;
  return (_selectEngineDefiniti14 = (_selectEngineDefiniti15 = selectEngineDefinition(state).features) === null || _selectEngineDefiniti15 === void 0 ? void 0 : _selectEngineDefiniti15.standard) !== null && _selectEngineDefiniti14 !== void 0 ? _selectEngineDefiniti14 : true;
};
const selectFeatureSystem = state => selectRSystems(state).length > 0 || selectUSystems(state).length > 0;
const selectFeaturePix = state => {
  var _selectEngineDefiniti16;
  if ((_selectEngineDefiniti16 = selectEngineDefinition(state).quickPlayPixSizes) !== null && _selectEngineDefiniti16 !== void 0 && _selectEngineDefiniti16.length) {
    return true;
  }
  return false;
};
const selectFeatureMarketplace = state => {
  var _selectEngineDefiniti17, _selectEngineDefiniti18;
  return !isRetailer && !isSelfService && ((_selectEngineDefiniti17 = (_selectEngineDefiniti18 = selectEngineDefinition(state).features) === null || _selectEngineDefiniti18 === void 0 ? void 0 : _selectEngineDefiniti18.marketplace) !== null && _selectEngineDefiniti17 !== void 0 ? _selectEngineDefiniti17 : false);
};
const selectFeatureSingles = state => {
  var _selectEngineDefiniti19, _selectEngineDefiniti20;
  return (_selectEngineDefiniti19 = (_selectEngineDefiniti20 = selectEngineDefinition(state).features) === null || _selectEngineDefiniti20 === void 0 ? void 0 : _selectEngineDefiniti20.singles) !== null && _selectEngineDefiniti19 !== void 0 ? _selectEngineDefiniti19 : false;
};
const selectFeatureExternal = state => !isRetailer && !isSelfService && selectMaxExternalSystemSize(state) > 0;
const selectPixSystems = state => {
  var _selectEngineDefiniti21;
  return (_selectEngineDefiniti21 = selectEngineDefinition(state).pixSystems) !== null && _selectEngineDefiniti21 !== void 0 ? _selectEngineDefiniti21 : DEFAULT_EMPTY_ARRAY;
};
const selectXpertRsys = state => {
  const activeCompetitionId = selectActiveCompetitionId(state);
  const competitionDetails = selectCompetitionDetails(state, activeCompetitionId);
  if (competitionDetails && competitionDetails.systemType === SYSTEM_RSYS) {
    return [competitionDetails.systemNumber];
  }
  return selectPixSystems(state);
};
const selectDefaultPixSize = state => {
  var _selectEngineDefiniti22, _selectEngineDefiniti23;
  return (_selectEngineDefiniti22 = (_selectEngineDefiniti23 = selectEngineDefinition(state).defaultCouponValues) === null || _selectEngineDefiniti23 === void 0 ? void 0 : _selectEngineDefiniti23.pixSize) !== null && _selectEngineDefiniti22 !== void 0 ? _selectEngineDefiniti22 : null;
};
const selectDefaultReduceModeCount = state => {
  var _selectEngineDefiniti24, _selectEngineDefiniti25;
  return (_selectEngineDefiniti24 = (_selectEngineDefiniti25 = selectEngineDefinition(state).defaultCouponValues) === null || _selectEngineDefiniti25 === void 0 ? void 0 : _selectEngineDefiniti25.reduceModeCount) !== null && _selectEngineDefiniti24 !== void 0 ? _selectEngineDefiniti24 : undefined;
};
const selectIsTipsinfoSupported = (state, tipsinfo, pageType) => {
  switch (pageType) {
    case pageTypes.RESULT:
      return selectEngineDefinition(state).tipsinfoSupportResult.includes(tipsinfo);
    case pageTypes.MY_BETS:
      return selectEngineDefinition(state).tipsinfoSupportMyBets.includes(tipsinfo);
    default:
      return selectEngineDefinition(state).tipsinfoSupport.includes(tipsinfo);
  }
};
const selectTipsinfoSupport = (state, pageType) => ({
  [TipsinfoTypes.Odds]: selectIsTipsinfoSupported(state, TipsinfoTypes.Odds, pageType),
  [TipsinfoTypes.StartOdds]: selectIsTipsinfoSupported(state, TipsinfoTypes.StartOdds, pageType),
  [TipsinfoTypes.SvenskaFolket]: selectIsTipsinfoSupported(state, TipsinfoTypes.SvenskaFolket, pageType),
  [TipsinfoTypes.TioTidningar]: selectIsTipsinfoSupported(state, TipsinfoTypes.TioTidningar, pageType),
  [TipsinfoTypes.Matchstart]: selectIsTipsinfoSupported(state, TipsinfoTypes.Matchstart, pageType),
  [TipsinfoTypes.Favourites]: selectIsTipsinfoSupported(state, TipsinfoTypes.Favourites, pageType),
  [TipsinfoTypes.Mutuals]: selectIsTipsinfoSupported(state, TipsinfoTypes.Mutuals, pageType),
  [TipsinfoTypes.Analys]: selectIsTipsinfoSupported(state, TipsinfoTypes.Analys, pageType),
  [TipsinfoTypes.OutcomeLabel]: true,
  [TipsinfoTypes.Ministat]: selectIsTipsinfoSupported(state, TipsinfoTypes.Ministat, pageType),
  [TipsinfoTypes.Compressed]: selectIsTipsinfoSupported(state, TipsinfoTypes.Compressed, pageType),
  [TipsinfoTypes.OverUnder]: selectIsTipsinfoSupported(state, TipsinfoTypes.OverUnder, pageType)
});
const selectEngineOutcomes = state => selectEngineDefinition(state).outcomes;
const selectOutcomesEventTypeId = state => {
  var _selectEngineDefiniti26, _selectEngineDefiniti27;
  return (_selectEngineDefiniti26 = (_selectEngineDefiniti27 = selectEngineDefinition(state).outcomes) === null || _selectEngineDefiniti27 === void 0 ? void 0 : _selectEngineDefiniti27.eventTypeId) !== null && _selectEngineDefiniti26 !== void 0 ? _selectEngineDefiniti26 : EventTypeId.EVENT_1X2;
};
const selectPixSystem = state => {
  var _selectEngineDefiniti28, _selectEngineDefiniti29;
  return (_selectEngineDefiniti28 = (_selectEngineDefiniti29 = selectEngineDefinition(state)) === null || _selectEngineDefiniti29 === void 0 ? void 0 : _selectEngineDefiniti29.pixSystem) !== null && _selectEngineDefiniti28 !== void 0 ? _selectEngineDefiniti28 : PixSystem.Xperten;
};
const selectModuleName = state => selectEngineDefinition(state).moduleEngine;
const selectMaxSelectionOutcomeGroup = state => selectEngineDefinition(state).maxSelectionOutcomeGroup;
setGlobal('svs.components.tipsen.engine.selectors.engineSelectors', {
  selectBetAmounts,
  selectBrandName,
  selectDisplayName,
  selectRSystems,
  selectUSystems,
  selectWeeks,
  selectSportkryssFactors,
  selectSportkryssBase,
  selectSystemTypes,
  selectReduction,
  selectReductionSum,
  selectDrawProductIds,
  selectXpertMsys,
  selectDefinitionId,
  selectMaxExternalSystemSize,
  selectFeatureStandard,
  selectFeatureSystem,
  selectFeaturePix,
  selectFeatureMarketplace,
  selectFeatureSingles,
  selectFeatureExternal,
  selectMinStake,
  selectXpertRsys,
  selectIsTipsinfoSupported,
  selectTipsinfoSupport,
  selectXpertMsysSpecialValues,
  selectQuickPlayPixSizes,
  selectDefaultPixSize,
  selectDefaultReduceModeCount,
  selectEngineOutcomes,
  selectOutcomesEventTypeId,
  selectPixSystem,
  selectAddonPixSizes,
  selectModuleName,
  selectSupportsCompetitions,
  selectMaxSelectionOutcomeGroup
});

 })(window);