(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/engine/assets/javascripts/definitions/fulltraff.js') >= 0) return;  svs.modules.push('/components/tipsen/engine/assets/javascripts/definitions/fulltraff.js');
"use strict";

let EventTypeId;
let productIds;
let xpertSizes;
let constants;
let brandMapping;
let SportTypes;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  constants = require('../constants.es6');
  productIds = trinidad.components.require('utils', 'products').productIds;
  EventTypeId = require('../../../../../sportinfo/common/assets/javascripts/constants.es6').EventTypeId;
  xpertSizes = require('../xpertSystems/8-13-signs.es6').xpertSizes;
  brandMapping = require('../../../../../lb-utils/brand-mapping/assets/javascripts/brand-mapping.es6');
  SportTypes = trinidad.components.require('sport', 'sport-types').api;
} else {
  EventTypeId = svs.components.sportinfo.common.constants.EventTypeId;
  productIds = svs.utils.products.productIds;
  xpertSizes = svs.components.tipsen.engine.xpertSystems.signs8rows13.xpertSizes;
  constants = svs.components.tipsen.engine.constants;
  brandMapping = svs.components.lbUtils.brandMapping.fn;
  SportTypes = svs.components.sport.sportTypes.SportTypes;
}
const fulltraffDefinition = {
  outcomes: {
    eventTypeId: EventTypeId.GOAL_COUNT,
    outcomeCount: 11,
    outcomeGroupCount: 1,
    outcomeHeatmap: {
      [SportTypes.Undefined]: {
        minValue: 0,
        minStartValue: 0,
        maxValue: 11,
        maxStartValue: 11
      },
      [SportTypes.Soccer]: {
        minValue: 0,
        minStartValue: 0,
        maxValue: 7,
        maxStartValue: 7
      },
      [SportTypes.Hockey]: {
        minValue: 0,
        minStartValue: 0,
        maxValue: 10,
        maxStartValue: 10
      }
    }
  },
  competitions: [],
  rSystems: [],
  uSystems: [],
  reduction: [],
  systems: [constants.systems.SYSTEM_MSYS],
  betAmounts: [],
  maxBetAmount: 0,
  weeks: [1, 5, 10],
  sportkryssFactors: [0],
  sportkryssBase: 10,
  addonPixSize: [0],
  maxSystemSize: 30000,
  drawProductIds: [productIds.FULLTRAFF],
  xpertMsys: xpertSizes,
  xpertMsysSpecialValues: [48, 96, 128, 144, 192, 256, 384, 512, 972, 1024],
  quickPlayPixSizes: [16, 48, 64, 96, 128, 144, 192, 256],
  displayName: brandMapping.getProductDisplayName(productIds.FULLTRAFF),
  brandName: brandMapping.getSiteBrand(productIds.FULLTRAFF),
  brandLogo: brandMapping.getProductBrand(productIds.FULLTRAFF),
  getDisplayNameByDraw: draw => brandMapping.getProductDisplayNameByDraw(draw, productIds.FULLTRAFF),
  getBrandNamesByDraw: draw => brandMapping.getProductBrandsByDraw(draw, productIds.FULLTRAFF),
  getBrandLogoByDraw: draw => brandMapping.getProductBrandByDraw(draw, productIds.FULLTRAFF),
  getBrandMenuColorByDraw: draw => brandMapping.getProductBrandMenuColorByDraw(draw, productIds.FULLTRAFF),
  maxExternalSystemSize: 30000,
  features: {
    marketplace: true,
    singles: false
  },
  getRelatedProduct: () => null,
  getTransfer: () => null,
  pixSystems: [],
  minGroupAmount: 10,
  minStake: 1,
  hasGuaranteedJackpot: true,
  hasJackpot: true,
  tipsinfoSupport: [constants.TipsinfoTypes.Odds, constants.TipsinfoTypes.StartOdds, constants.TipsinfoTypes.SvenskaFolket, constants.TipsinfoTypes.Matchstart, constants.TipsinfoTypes.Favourites, constants.TipsinfoTypes.Analys, constants.TipsinfoTypes.Ministat, constants.TipsinfoTypes.Compressed, constants.TipsinfoTypes.OverUnder],
  tipsinfoSupportResult: [constants.TipsinfoTypes.Odds, constants.TipsinfoTypes.SvenskaFolket, constants.TipsinfoTypes.Favourites],
  tipsinfoSupportMyBets: [constants.TipsinfoTypes.Odds, constants.TipsinfoTypes.SvenskaFolket, constants.TipsinfoTypes.Favourites],
  loadDrawsApiPath: 'fulltraff',
  moduleEngine: 'fulltraff',
  defaultCouponValues: {
    betAmount: 1,
    pixSize: 48,
    reduceModeCount: 20
  },
  pixType: 'Fulltraff',
  pixSystem: constants.PixSystem.SportPix,
  externalChunkSize: {
    SROW: 1000,
    MSYS: 550,
    SROW_MARKETPLACE_GROUP: 950,
    MSYS_MARKETPLACE_GROUP: 515
  },
  couponSettings: [],
  maxWagerAmount: 0,
  maxSelectionOutcomeGroup: 0
};
if (svs.isServer) {
  module.exports = fulltraffDefinition;
} else {
  setGlobal('svs.components.tipsen.engine.definitions.fulltraff', fulltraffDefinition);
}

 })(window);