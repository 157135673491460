(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-additions-components/assets/javascripts/sportkryss.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-additions-components/assets/javascripts/sportkryss.js');
"use strict";


const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  format
} = svs.utils;
const {
  RadioGroup,
  ControlGroup,
  RadioButton,
  Fieldset,
  Form
} = svs.ui.reactForm;
const {
  actions
} = svs.components.tipsen.engine;
const {
  selectSportkryssFactors,
  selectSportkryssBase
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  selectCurrentSportkryssFactor
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  BaseAddons
} = svs.components.tipsen.baseAddons;
const {
  SportkryssNumberArea
} = svs.components.tipsen.couponAdditionsComponents.sportkryss;
const Sportkryss = () => {
  const couponId = useCouponId();
  const currentSportkryssFactor = useSelector(state => selectCurrentSportkryssFactor(state, couponId));
  const sportkryssFactors = useSelector(selectSportkryssFactors);
  const sportKryssBase = useSelector(selectSportkryssBase);
  const dispatch = useDispatch();
  const sportkryssCount = sportkryssFactor => {
    if (sportkryssFactor === 0) {
      return 'Nej';
    }
    return "".concat(format.Currency(sportkryssFactor * sportKryssBase), " kr");
  };
  return React.createElement(BaseAddons, {
    description: "V\xE4lj ett sjusiffrigt nummer och ha chans att vinna upp till 30 miljoner!",
    dialog: "Sportkryss \xE4r ett till\xE4ggsspel.  V\xE4lj ett sjusiffrigt nummer och en insats p\xE5 10, 20 eller 30 kronor.",
    icon: "games",
    id: "sportkryss",
    title: "Sportkryss"
  }, React.createElement(Form, {
    className: "tipsen-addition-form"
  }, React.createElement(Fieldset, {
    className: "sportkryss-fieldset-grid"
  }, React.createElement("legend", {
    className: "sr-only"
  }, "Vill du spela p\xE5 Sportkryss?"), React.createElement(ControlGroup, null, React.createElement(RadioGroup, {
    size: "200"
  }, sportkryssFactors.map((sportkryssFactor, index) => {
    const checked = currentSportkryssFactor === sportkryssFactor;
    return React.createElement(RadioButton, {
      "aria-checked": checked,
      checked: checked,
      className: checked ? 'f-bold' : '',
      key: sportkryssFactor,
      onChange: () => dispatch(actions.setSportkryssFactorIndex({
        couponId,
        sportkryssFactorIndex: index
      })),
      tabIndex: checked ? 0 : -1,
      value: sportkryssFactor
    }, sportkryssCount(sportkryssFactor));
  }))), React.createElement(SportkryssNumberArea, null))));
};
setGlobal('svs.components.tipsen.couponAdditionsComponents.Sportkryss', Sportkryss);

 })(window);