(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/configs/assets/javascripts/overrides/sport/basketball.js') >= 0) return;  svs.modules.push('/components/sportinfo/configs/assets/javascripts/overrides/sport/basketball.js');
"use strict";


const newEnetpulseWidgets = svs.core.detect.feature('ft-enetpulse-new');
const {
  constants: {
    SportinfoPathNames,
    SvsViewType
  }
} = svs.components.sportinfo.common;
const {
  commonConfig
} = svs.components.sportinfo.configs.sportConfigs;
const {
  ContentProvider
} = svs.components.sportinfo.common.constants;
const {
  ConfigBuilder
} = svs.components.sportinfo.configBuilder;
const {
  menuItems: enetpulseMenuItems,
  content: enetpulseContent
} = new ConfigBuilder().addTab(SportinfoPathNames.MatchInfo, tab => {
  tab.setAll([{
    type: SvsViewType.MatchInfo,
    provider: ContentProvider.Svs
  }]);
}).addTab(SportinfoPathNames.News, tab => {
  tab.setAll([{
    type: SvsViewType.News,
    provider: ContentProvider.Svs
  }]);
}).build();
setGlobal('svs.components.sportinfo.configs.sportConfigs.sportTypeOverrides', {
  basketballConfig: newEnetpulseWidgets ? {
    menuItems: enetpulseMenuItems,
    content: enetpulseContent,
    config: 'basketball' 
  } : commonConfig
});

 })(window);