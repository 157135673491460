(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-card/assets/javascripts/draw-card-variants/draw-card-mini.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-card/assets/javascripts/draw-card-variants/draw-card-mini.js');
"use strict";


const {
  DrawCard,
  useConditionalWrapper
} = svs.components.tipsen.drawCard;
const {
  ReactIcon
} = svs.ui;
const {
  DRAW_CARD_ICON_SIZE,
  DRAW_STATE,
  DRAW_STATE_TEXTS
} = svs.components.tipsen.drawCard.constants;
const {
  SpeechBubble,
  SpeechBubbleSport3
} = svs.ui.ReactSpeechBubble;
const {
  DisabledOverlay
} = svs.components.tipsen.drawCard.components;
const {
  HorizontalTextAnimation
} = svs.components.lbUi;
const DisabledOverlayWrapper = _ref => {
  let {
    children
  } = _ref;
  return React.createElement(DisabledOverlay, {
    isMini: true
  }, children);
};
const IconBundle = _ref2 => {
  let {
    icon,
    children
  } = _ref2;
  return React.createElement("div", {
    className: "pg_draw_card_mini__icon_bundle"
  }, icon && React.createElement(ReactIcon, {
    icon: icon,
    size: DRAW_CARD_ICON_SIZE.MINI
  }), children);
};
const LiveLabel = () => React.createElement("div", {
  className: "pg_draw_card_mini__live_label"
}, "Live");
const LeftColumn = _ref3 => {
  let {
    state,
    regCloseTime
  } = _ref3;
  if (state === DRAW_STATE.CANCELLED) {
    return React.createElement(IconBundle, null, React.createElement(DrawCard.Status, {
      isCancelled: true
    }, DRAW_STATE_TEXTS.CANCELLED));
  }
  if (state === DRAW_STATE.IS_LIVE) {
    return React.createElement(LiveLabel, null);
  }
  return React.createElement(IconBundle, {
    icon: "clock-simple"
  }, React.createElement(DrawCard.RegCloseTime, null, state === DRAW_STATE.FINISHED ? 'Avslutad' : regCloseTime));
};
const CenterColumn = _ref4 => {
  let {
    currencyCounterUniqueId,
    state,
    netSale
  } = _ref4;
  if (state === DRAW_STATE.NOT_OPENED) {
    return React.createElement(IconBundle, null, React.createElement(DrawCard.Status, null, DRAW_STATE_TEXTS.NOT_OPENED));
  }
  return React.createElement(IconBundle, {
    icon: "money"
  }, React.createElement(DrawCard.NetSale, {
    currencyCounterUniqueId: currencyCounterUniqueId
  }, netSale));
};
const RightColumn = _ref5 => {
  let {
    extraMoney
  } = _ref5;
  if (!(extraMoney !== null && extraMoney !== void 0 && extraMoney.amount)) {
    return null;
  }
  if (extraMoney !== null && extraMoney !== void 0 && extraMoney.isGuaranteeJackpot) {
    return React.createElement(IconBundle, {
      icon: "hot"
    }, React.createElement(HorizontalTextAnimation, {
      classNames: "pg_draw_card_component pg_draw_card_mini__guarantee_jackpot"
    }, "En ensam vinnare \xE4r garanterad ".concat(extraMoney.amount, " kr")));
  }
  return React.createElement(IconBundle, {
    icon: "hot"
  }, React.createElement(DrawCard.ExtraAmount, null, extraMoney.amount));
};
const DrawCardMini = _ref6 => {
  let {
    branding,
    className,
    competitionName,
    uniqueId,
    extraMoney,
    icon = 'game-sport',
    league,
    netSale,
    regCloseTime,
    state,
    titleElement
  } = _ref6;
  const classNames = ['pg_draw_card_mini'];
  className && classNames.push(className);
  const ConditionalDisabledOverlayWrapper = useConditionalWrapper(DisabledOverlayWrapper, {
    skip: state !== DRAW_STATE.TEMPORARY_CLOSED
  });
  return React.createElement(ConditionalDisabledOverlayWrapper, null, React.createElement(DrawCard, {
    branding: branding,
    className: classNames.join(' ')
  }, React.createElement("div", {
    className: "pg_draw_card_mini__section"
  }, React.createElement(DrawCard.Title, {
    icon: icon,
    iconSize: DRAW_CARD_ICON_SIZE.MINI
  }, titleElement), competitionName ? React.createElement(SpeechBubble, {
    size: "100"
  }, React.createElement(SpeechBubbleSport3, null, competitionName)) : React.createElement(DrawCard.League, null, league)), React.createElement("div", {
    className: "pg_draw_card_mini__section"
  }, React.createElement(LeftColumn, {
    regCloseTime: regCloseTime,
    state: state
  }), React.createElement(CenterColumn, {
    currencyCounterUniqueId: uniqueId,
    netSale: netSale,
    state: state
  }), React.createElement(RightColumn, {
    extraMoney: extraMoney
  }))));
};
setGlobal('svs.components.tipsen.drawCard.DrawCardMini', DrawCardMini);

 })(window);